/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

import * as APITypes from "../API";
type GeneratedQuery<InputType, OutputType> = string & {
  __generatedQueryInput: InputType;
  __generatedQueryOutput: OutputType;
};

export const getNews = /* GraphQL */ `query GetNews($id: ID!) {
  getNews(id: $id) {
    id
    date
    content
    query_term
    title
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<APITypes.GetNewsQueryVariables, APITypes.GetNewsQuery>;
export const listNews = /* GraphQL */ `query ListNews($filter: ModelNewsFilterInput, $limit: Int, $nextToken: String) {
  listNews(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      date
      content
      query_term
      title
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<APITypes.ListNewsQueryVariables, APITypes.ListNewsQuery>;
export const getUser = /* GraphQL */ `query GetUser($id: ID!) {
  getUser(id: $id) {
    id
    expires_at
    phone
    refresh
    stripe_customer_id
    subscription_id
    email
    modified_time
    created_time
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<APITypes.GetUserQueryVariables, APITypes.GetUserQuery>;
export const listUsers = /* GraphQL */ `query ListUsers(
  $filter: ModelUserFilterInput
  $limit: Int
  $nextToken: String
) {
  listUsers(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      expires_at
      phone
      refresh
      stripe_customer_id
      subscription_id
      email
      modified_time
      created_time
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<APITypes.ListUsersQueryVariables, APITypes.ListUsersQuery>;
