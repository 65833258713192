import React, { useState } from 'react';
import { Form, Input, Button, Checkbox, Modal, message, Typography } from 'antd';
import { listUsers } from './graphql/queries';
import { signUp, signIn, SignInOutput } from 'aws-amplify/auth';
import { generateClient } from 'aws-amplify/api';
import { CheckboxChangeEvent } from 'antd/es/checkbox';
import { redirectToPayment } from './HomePage';
import { useNavigate } from "react-router-dom";
import ForgotPassword from './ForgotPassword';
const { Text } = Typography;

const client = generateClient();

interface LoginFormInputProps {
    hideModal: Function;
    goToForgotPassword: Function;
}

// Define the type for form values
interface LoginFormValues {
    email: string;
    password: string;
    first: boolean;
}

const LoginForm: React.FC<LoginFormInputProps> = (props: LoginFormInputProps) => {
    const [hasStripe, setHasStripe] = useState(true);
    const [phoneNumber, setPhoneNumner] = useState('');
    const [createPassword, setCreatePassword] = useState(false);
    const [form] = Form.useForm();
    const navigate = useNavigate();

    const passwordPolicy = (_: any, value: string) => {
        if (!value) {
            return Promise.resolve();
        }
        if (!createPassword) {
            return Promise.resolve();
        }
        const hasLowerCase = /[a-z]/.test(value);
        const hasUpperCase = /[A-Z]/.test(value);
        const hasNumeric = /\d/.test(value);
        const hasSymbol = /[!@#$%^&*(),.?":{}|<>]/.test(value);
        if (!hasLowerCase || !hasUpperCase || !hasNumeric || !hasSymbol || value.length < 8) {
            return Promise.reject(new Error('Password must be at least 8 characters long, include upper and lowercase letters, numerals, and symbols.'));
        }
        return Promise.resolve();
    };

    const onFinish = (values: LoginFormValues) => {
        if (!hasStripe) {
            form.resetFields();
            redirectToPayment();
        } else if (createPassword) {
            signUp({
                username: values.email,
                password: values.password,
                options: {
                    userAttributes: {
                        email: values.email,
                        phone_number: phoneNumber
                    },
                    // optional
                    autoSignIn: true // or SignInOptions e.g { authFlowType: "USER_SRP_AUTH" }
                }
            }).then((response: any) => {
                console.log('Create password success: ', response);
                message.success('Create password success! Please verify your email!');
                form.resetFields();
                props.hideModal()
            }).catch((error: Error) => {
                message.error(error.message);
                console.log('Error: ', error);
            });
        } else {
            signIn({ username: values.email, password: values.password })
                .then((response: SignInOutput) => {
                    console.log('Sign in success: ', response);
                    if (response.isSignedIn) {
                        message.success('Sign in success!');
                        form.resetFields();
                        navigate("/admin");
                    } else {
                        message.error(`Please verify your email! \n Go to ${values.email}`);
                    }
                }).catch((error: Error) => {
                    console.log('Error: ', error.message);
                    message.error(error.message);
                });
        }
    };

    const onFinishFailed = (errorInfo: any) => {
        console.log('Failed:', errorInfo);
    };

    const handleEmailBlur = (e: React.FocusEvent<HTMLInputElement>) => {
        // Extract the value from the event
        const email = e.target.value;
        if (!email || email.length == 0) {
            return
        }

        // Perform your validation or processing logic here
        // For example, check if the email format is correct (basic example)
        if (email && !/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(email)) {
            return
        }

        // verify user email exists in graphQL
        client.graphql({
            query: listUsers,
            variables: {
                filter: {
                    email: {
                        eq: email
                    }
                }
            }
        }).then((response: any) => {
            if (response.data.listUsers.items.length === 0) {
                message.info('Email not found, please sign up first');
                setHasStripe(false);
            } else {
                setPhoneNumner(response.data.listUsers.items[0].phone);
                setHasStripe(true);
            }
        }).catch((error: any) => {
            console.log('Error: ', error);
        });
    };

    const handleCreatePasswordChange = (e: CheckboxChangeEvent) => {
        setCreatePassword(e.target.checked);
    }

    const validateConfirmPassword = (_: any, value: string) => {
        if (value && value !== form.getFieldValue('password')) {
            return Promise.reject(new Error('Entered passwords do not match!'));
        }
        return Promise.resolve();
    };

    return (
        <Form
            form={form}
            name="basic"
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete="off"
            layout="vertical"
            style={{ maxWidth: '300px', margin: '0 auto' }}
        >
            <Form.Item
                label="Email"
                name="email"
                rules={[{ required: true, message: 'Please input your email!' },
                { type: 'email', message: 'Please enter a valid email!' }
                ]}
            >
                <Input onBlur={handleEmailBlur} />
            </Form.Item>
            {!hasStripe ? null :
                <Form.Item name="first" valuePropName="false">
                    <Checkbox checked={createPassword} onChange={handleCreatePasswordChange}>First time?</Checkbox>
                </Form.Item>
            }

            {!hasStripe ? null :
                <Form.Item
                    label="Password"
                    name="password"
                    rules={[{ required: true, message: 'Please input your password!' }, { validator: passwordPolicy }]}
                >
                    <Input.Password />
                </Form.Item>
            }

            {!hasStripe ? null :
                <div style={{ marginBottom: "20px", marginTop: "-10px" }}><a onClick={() => props.goToForgotPassword()}><Text underline>Forgot Password</Text></a></div>
            }

            {
                !hasStripe ? null :
                    !createPassword ? null :
                        <Form.Item
                            label="Confirm"
                            name="confirm_password"
                            rules={[{ required: true, message: 'Please confirm your password!' },
                            { validator: validateConfirmPassword }]}
                        >
                            <Input.Password />
                        </Form.Item>

            }

            <Form.Item>
                <Button type="primary" htmlType="submit">
                    {!hasStripe ? "Sign up" : (createPassword ? "Create password" : "Log in")}
                </Button>
            </Form.Item>
        </Form >
    );
};


const LoginModal: React.FC = () => {
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [isForgotPassword, setIsForgotPassword] = useState(false);

    const showModal = () => {
        setIsModalVisible(true);
    };

    const handleOk = () => {
        setIsModalVisible(false);
    };

    const handleCancel = () => {
        setIsModalVisible(false);
    };

    return (
        <>
            <p>Already a member? &nbsp;
                <a style={{ color: 'black', textDecoration: 'underline', cursor: 'pointer' }}
                    onClick={showModal}>Login</a>
            </p>
            <Modal title="Login" open={isModalVisible} onOk={handleOk} onCancel={handleCancel} footer={null}>
                {isForgotPassword ? <ForgotPassword email='123@gmail.com'
                    goBackLogin={() => setIsForgotPassword(false)} /> :
                    <LoginForm hideModal={() => setIsModalVisible(false)}
                        goToForgotPassword={() => setIsForgotPassword(true)} />}
            </Modal>
        </>
    );
};

export default LoginModal;
