// Code for the AdminPage
import { Button, Flex, Typography, message, Spin } from "antd";
import { post } from 'aws-amplify/api';
import { signOut, fetchAuthSession } from "aws-amplify/auth";
import { useNavigate } from "react-router-dom";
import MyLayout from "./MyLayout";
import { useEffect, useState } from "react";
const { Text } = Typography;


const AdminPage: React.FC = () => {
    const [email, setEmail] = useState('')
    const [userStatus, setUserStatus] = useState('default init')
    const navigate = useNavigate();

    // Fetch the user's email and status when initially loading the page
    useEffect(() => {
        fetchAuthSession()
            .then(data => {
                try {
                    const inputEmail = data.tokens?.idToken?.payload.email as string;
                    setEmail(inputEmail);
                    fetchUserStatus(inputEmail);
                } catch (error) {
                    console.log(error);
                    message.error("Error. Please login again.")
                }
            })
            .catch(err => {
                message.error("Error. Please login again.")
                console.log(err);
            });
    });



    async function fetchUserStatus(email: string) {
        const restOperation = post({
            apiName: 'stripewebhooks',
            path: '/stripe-manage/subscriptions',
            options: {
                body: {
                    email: email
                }
            }
        });
        try {
            const { body } = await restOperation.response;
            const response = await body.json() as any;
            console.log(response.status);
            setUserStatus(response.status);
        } catch (e) {
            message.error('Error');
        }
    }

    async function createCustomerPortalSession() {
        const restOperation = post({
            apiName: 'stripewebhooks',
            path: '/stripe-manage/customer-portal-session',
            options: {
                body: {
                    email: email
                }
            }
        });
        try {
            const { body } = await restOperation.response;
            const response = await body.json() as any;
            if (response)
                window.location.href = response.url
        } catch (e) {
            message.error('Error');
        }
    }

    const paymentLink2 = process.env.REACT_APP_PAYMENT_URL_2 as string;

    return (
        <MyLayout isAdmin InnerComponent={
            <Flex justify="center" align="center" vertical>
                <Text style={{ marginTop: '40px' }}>Welcome {email}</Text>
                {userStatus === 'active' || userStatus === 'trialing' ? <Button style={{ marginTop: '40px' }} type="primary" onClick={() => {
                    message.loading("")
                    createCustomerPortalSession();
                }}>Manage Subscription</Button> : null}
                {userStatus === 'active' || userStatus === 'trialing' || userStatus === 'default init' ? null : <Button style={{ marginTop: '40px' }} type="primary" onClick={() => {
                    window.location.href = `${paymentLink2}?prefilled_email=${email}&client_reference_id=${email}`
                }}>Renew Subscription</Button>}
                <Button style={{ marginTop: '40px' }} onClick={async () => {
                    await signOut();
                    navigate("/");
                }}>Log Out</Button>
            </Flex>
        } />
    );
}

export default AdminPage;
