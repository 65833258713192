import { MessageOutlined, GlobalOutlined, ClockCircleOutlined, FileTextOutlined } from "@ant-design/icons";
import LoginModal from "./LoginModal";
import MyLayout from "./MyLayout";
import { Button, Card, Col, Flex, Row, Typography, Carousel, Steps } from "antd";
import { record } from "aws-amplify/analytics";
import home from './home_screen.webp';
import { Helmet } from "react-helmet";

const { Step } = Steps;
const { Title, Text, Paragraph } = Typography;


const paymentLink = process.env.REACT_APP_PAYMENT_URL as string;

export const redirectToPayment = () => {
    record({ name: "sign up" })
    window.location.href = paymentLink;
};

const DescriptionCard: React.FC<{}> = () => (
    <div style={{ marginTop: "42px", marginBottom: "42px" }}>
        <Row justify="center">
            <Col xs={24} sm={20} md={20}>
                <Card
                    style={{ height: '100%', padding: '0px 16px' }}
                >
                    <h2 style={{ textAlign: 'center', marginBottom: '20px' }}>About BriefWires</h2>
                    <Text>
                        In today's fast-paced world, BriefWires sending top news to your phone once per day offers a streamlined way to stay informed without feeling overwhelmed. <br /> <br />
                        We filter the day's most important news into a concise digest, ensuring you're well-informed in minimal time. BriefWires provides a quick snapshot of new events without the need to navigate multiple sources.  <br /> <br />
                        By limiting news intake to once daily, we help avoid the stress and distraction of constant updates. We select only essential news, reducing the burden of information overload and helping maintain focus and productivity.  <br /> <br />
                        BriefWires help you keep up with the news without the downsides of constant connectivity.
                    </Text>
                </Card>
            </Col>
        </Row>

    </div >
);

const FeatureCard: React.FC<{ icon: React.ReactNode; title: string; description: string }> = ({ icon, title, description }) => (
    <Col xs={24} sm={12} md={8}>
        <Card
            hoverable
            style={{ textAlign: 'center', height: '100%', padding: '16px' }}
            cover={icon}
        >
            <Card.Meta
                title={title}
                description={description}
            />
        </Card>
    </Col>
);

const FeaturesSection: React.FC = () => (
    <div style={{ background: '#f0f2f5', padding: '50px 0' }}>
        <Row gutter={[16, 16]} justify="space-around">
            <FeatureCard
                icon={<GlobalOutlined style={{ fontSize: '48px', color: '#1890ff' }} />}
                title="Top Important News"
                description="Stay informed with the day's most critical news stories, curated to keep you updated on world events without overwhelming your inbox."
            />
            <FeatureCard
                icon={<MessageOutlined style={{ fontSize: '48px', color: '#1890ff' }} />}
                title="Concise Summaries, No Noise"
                description="Receive succinct news directly to your SMS inbox. Just the facts, no fluff, ensuring you're informed, not inundated."
            />
            <FeatureCard
                icon={<ClockCircleOutlined style={{ fontSize: '48px', color: '#1890ff' }} />}
                title="Timely Updates"
                description="Get your daily news digest every morning, perfectly timed so you can start your day informed and ready to take on the world."
            />
        </Row>
    </div>
);

const HowItWorksSection: React.FC = () => (
    <div style={{ marginTop: "42px", marginBottom: "52px", padding: '50px 15px', background: '#ffffff' }}>
        <Row justify="center">
            <Col span={24} md={20} lg={16}>
                <h2 style={{ textAlign: 'center', marginBottom: '40px' }}>How It Works</h2>
                <Steps direction="vertical" current={-1}>
                    <Step
                        title={<span style={{ color: 'black', fontWeight: 'bold' }}>Curated News Selection</span>}
                        description="We pick the day's most impactful news, ensuring you stay informed on significant world events without the clutter."
                        icon={<GlobalOutlined style={{ marginTop: "10px", color: '#1890ff' }} />}
                    />
                    <Step
                        title={<span style={{ color: 'black', fontWeight: 'bold' }}>Concise, Factual Summaries</span>}
                        description="We distill each news story to its essence, providing you with factual, unbiased summaries that get straight to the point."
                        icon={<FileTextOutlined style={{ marginTop: "10px", color: '#1890ff' }} />}
                    />
                    <Step
                        title={<span style={{ color: 'black', fontWeight: 'bold' }}>Daily SMS Briefing</span>}
                        description="Every morning, receive a concise briefing directly to your SMS inbox, keeping you informed and ready to start your day."
                        icon={<MessageOutlined style={{ marginTop: "10px", color: '#1890ff' }} />}
                    />
                </Steps>
            </Col>
        </Row >
    </div >
);

const testimonials = [
    {
        name: "Jane Doe",
        text: "The daily news is perfect for my busy schedule. Concise and right to the point!",
        affiliation: "Product Manager",
    },
    {
        name: "John Smith",
        text: "I love starting my day informed. This service has made it so much easier to stay up-to-date with world events.",
        affiliation: "Teacher",
    },
    {
        name: "Alex Johnson",
        text: "As someone who's constantly on the go, receiving a quick digest of the top news directly to my phone is invaluable. It's exactly what I need!",
        affiliation: "Entrepreneur",
    },
    {
        name: "Samantha Lee",
        text: "I've tried many news services, but none are as straightforward and focused as this one. It's news without the noise, straight to my SMS.",
        affiliation: "Freelancer",
    },
    {
        name: "Michael Chen",
        text: "Getting the day's most important news in a concise format has drastically improved my morning routine. I'm always well-informed, thanks to this service.",
        affiliation: "Software Developer",
    },
    {
        name: "Linda García",
        text: "This service has been a game-changer for keeping up with news. The briefings are to the point, informative, and delivered at the perfect time.",
        affiliation: "Marketing Specialist",
    }
];


const TestimonialsSection: React.FC = () => (
    <Row justify="center" style={{ padding: '50px 15px', maxWidth: '100%', margin: '0' }} >
        <Col span={24} md={20}>
            <Carousel style={{ maxWidth: '100%', height: 'auto' }} autoplay dots={false}>
                {testimonials.map((testimonial, index) => (
                    <div key={index}>
                        <Card>
                            <Paragraph>"{testimonial.text}"</Paragraph>
                            <Text strong>{testimonial.name}</Text>, <Text italic>{testimonial.affiliation}</Text>
                        </Card>
                    </div>
                ))}
            </Carousel>
        </Col>
    </Row >
);

const GetStartButton: React.FC = () => (
    <Button type="primary" size="large" onClick={redirectToPayment}>Get Started Free</Button>
);

const HomePage: React.FC = () => {
    return (
        <MyLayout InnerComponent={
            <Flex justify="center" align="center" vertical>
                <Helmet>
                    <meta charSet="utf-8" />
                    <link rel="canonical" href={"https://www.briefwires.com"} />
                </Helmet>
                <Title level={1} style={{ fontSize: '32px' }}>Get Your Daily News Digest Directly via SMS</Title>
                <Title level={1} style={{ fontSize: '28px' }}>Start Your Morning Informed with Concise News</Title>
                <div style={{ height: "25px" }} />
                {/* <GetStartButton /> */}
                <LoginModal />
                <div style={{ height: "18px" }} />
                <img style={{ height: "400px" }} src={home} alt="news on phone screen" />
                <div style={{ height: "38px" }} />
                {/* <GetStartButton /> */}
                <FeaturesSection />
                {/* <GetStartButton /> */}
                <DescriptionCard />
                {/* <GetStartButton /> */}
                <HowItWorksSection />
                {/* <GetStartButton /> */}
                <TestimonialsSection />
            </Flex >
        } />
    );
}

export default HomePage;
