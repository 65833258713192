import { useParams } from 'react-router-dom';
import MyLayout from "./MyLayout";
import { listNews } from './graphql/queries';
import { useEffect, useState } from "react";
import { News } from "./API";
import { generateClient } from 'aws-amplify/api';
import { Col, Row, Skeleton, Typography } from 'antd';
import { Helmet } from 'react-helmet';
const { Title, Paragraph } = Typography;


const client = generateClient();

const Details = ({ news }: { news: News }) => {
    const content = news.content?.split('&&&').map((line, idx) => {
        return <Paragraph style={{ fontSize: "18px" }} key={idx}>{line}</Paragraph>
    });
    return (
        <div>
            <Title level={1}>{news.title}</Title>
            <Paragraph>{news.date}</Paragraph>
            {content}
        </div>
    )
}

const NewsDetailPage: React.FC = () => {
    const [newsItem, setNewsItem] = useState<News>();
    const { query_term } = useParams();

    useEffect(() => {
        fetchNews();
    }, []);

    const fetchNews = async () => {
        try {
            client.graphql({
                query: listNews,
                variables: { limit: 100, filter: { query_term: { eq: query_term } } }
            }).then((response: any) => {
                console.log(query_term);
                console.log(response.data.listNews.items);
                if (response.data.listNews.items.length === 0) {
                    console.log('No news items found');
                } else {
                    var newsList = response.data.listNews.items;
                    setNewsItem(newsList[0]);
                }
            }).catch((error: any) => {
                console.log('Error: ', error);
            });
        } catch (err) {
            console.error("Error fetching news", err);
        }
    };
    return (
        <MyLayout InnerComponent={
            <div>
                <Helmet>
                    <meta charSet="utf-8" />
                    <title>{query_term?.replaceAll('-', ' ') + "- BriefWires"}</title>
                    <link rel="canonical" href={"https://www.briefwires.com/news/" + query_term} />
                </Helmet>
                <Row justify="center">
                    <Col span={24} md={24} lg={16}>
                        {newsItem ? <Details news={newsItem} /> : <div><Skeleton /></div>}
                    </Col>
                </Row>
            </div >
        } />
    )
}

export default NewsDetailPage;
