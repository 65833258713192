import React, { useState, useEffect } from 'react';
import { Form, Input, Button, message } from 'antd';
import { resetPassword, confirmResetPassword } from 'aws-amplify/auth';

interface ForgotPasswordInputProps {
    email: string;
    goBackLogin: Function
}

const ForgotPassword: React.FC<ForgotPasswordInputProps> = (props: ForgotPasswordInputProps) => {
    const [form] = Form.useForm(); // Step 1: Create a form instance
    const [email, setEmail] = useState(props.email);
    const [verificationCode, setVerificationCode] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [stage, setStage] = useState(1); // 1: Email submission, 2: Verification code and new password submission

    useEffect(() => {
        form.resetFields(); // Reset the form fields when the component is displayed
    }, [form, stage]);

    const passwordPolicy = (_: any, value: string) => {
        if (!value) {
            return Promise.resolve();
        }
        const hasLowerCase = /[a-z]/.test(value);
        const hasUpperCase = /[A-Z]/.test(value);
        const hasNumeric = /\d/.test(value);
        const hasSymbol = /[!@#$%^&*(),.?":{}|<>]/.test(value);
        if (!hasLowerCase || !hasUpperCase || !hasNumeric || !hasSymbol || value.length < 8) {
            return Promise.reject(new Error('Password must be at least 8 characters long, include upper and lowercase letters, numerals, and symbols.'));
        }
        return Promise.resolve();
    };

    const sendResetCode = async () => {
        try {
            await resetPassword({ username: email });
            message.success('Verification code sent. Please check your email.');
            setStage(2); // Move to next stage after successful email submission
        } catch (error) {
            message.error('Failed to send verification code. Please try again.');
        }
    };

    const resetPasswordClick = async () => {
        try {
            await confirmResetPassword({ username: email, confirmationCode: verificationCode, newPassword: newPassword });
            message.success('Password has been successfully reset. You can now login with your new password.');
        } catch (error) {
            message.error('Failed to reset password. Please ensure the verification code and email are correct.');
        }
    };

    const goBack = () => {
        setStage(1); // Go back to the email submission stage
    };

    return (
        <div>
            {stage === 1 && (
                <Form
                    form={form} // Step 2: Pass the form instance to the Form component
                    autoComplete="off"
                    layout="vertical"
                    style={{ maxWidth: '300px', margin: '0 auto' }} name="send_reset_code" onFinish={sendResetCode}>
                    <Form.Item
                        label="Email"
                        name="email"
                        rules={[{ required: true, message: 'Please input your email!' }]}
                    >
                        <Input
                            type="email"
                            onChange={(e) => setEmail(e.target.value)}
                        />
                    </Form.Item>
                    <Form.Item>
                        <Button style={{ marginRight: '10px' }} onClick={() => { form.resetFields(); props.goBackLogin(); }}>
                            Back
                        </Button>
                        <Button type="primary" htmlType="submit">
                            Send Reset Code
                        </Button>
                    </Form.Item>
                </Form>
            )}
            {stage === 2 && (
                <Form
                    form={form} // Step 2: Pass the form instance to the Form component
                    autoComplete="off"
                    layout="vertical"
                    style={{ maxWidth: '300px', margin: '0 auto' }} name="reset_password" onFinish={resetPasswordClick}>
                    <Form.Item
                        label="Verification Code"
                        name="verificationCode"
                        rules={[{ required: true, message: 'Please enter your verification code!' }]}
                    >
                        <Input
                            onChange={(e) => setVerificationCode(e.target.value)}
                        />
                    </Form.Item>
                    <Form.Item
                        label="New Password"
                        name="newPassword"
                        rules={[{ required: true, message: 'Please enter your new password!' }, { validator: passwordPolicy }]}
                    >
                        <Input.Password
                            onChange={(e) => setNewPassword(e.target.value)}
                        />
                    </Form.Item>
                    <Form.Item>
                        <Button style={{ marginRight: '10px' }} onClick={goBack}>
                            Back
                        </Button>
                        <Button type="primary" htmlType="submit">
                            Reset Password
                        </Button>
                    </Form.Item>
                </Form>
            )}
        </div>
    );
};

export default ForgotPassword;
