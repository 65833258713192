import { Link } from "react-router-dom";
import logo from './logo.svg';
import { useEffect, useState } from 'react';
import { redirectToPayment } from './HomePage';

import { Typography, Layout, Flex, Modal, Button } from 'antd';
const { Header, Content, Footer } = Layout;
const { Title, Text } = Typography;

interface MyLayoutProps {
    InnerComponent: React.ReactNode;
    isAdmin?: boolean;
}

const MyLayout: React.FC<MyLayoutProps> = ({ InnerComponent, isAdmin }) => {
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    const handleResize = () => {
        setWindowWidth(window.innerWidth);
    };

    useEffect(() => {
        window.addEventListener('resize', handleResize);

        return () => window.removeEventListener('resize', handleResize);
    }, []);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const showModal = () => {
        setIsModalOpen(true);
    };
    const handleOk = () => {
        setIsModalOpen(false);
    };
    const handleCancel = () => {
        setIsModalOpen(false);
    };
    const justifyContent = windowWidth < 768 ? 'center' : 'space-between';
    return (
        <Layout style={{ minHeight: '100vh' }}>
            <Modal open={isModalOpen} footer={null} onOk={handleOk} onCancel={handleCancel}>
                <p>
                    Ink River, LLC<br />
                    contact@ink-river.com<br />
                    651 North Broad Street, Suite 201<br />
                    Middletown, DE, 19709</p>
            </Modal>
            <Header
                style={{
                    position: 'sticky',
                    top: 0,
                    zIndex: 1,
                    width: '100%',
                    display: 'flex',
                    alignItems: 'center',
                    backgroundColor: 'white',
                    justifyContent: justifyContent,
                }}
                className='My-header'
            >
                <Flex align="center">
                    <Link to={'/'}>
                        <img src={logo} className="App-logo" alt="logo" />
                    </Link>
                    <Title level={1} style={{ marginBottom: '18px', fontSize: '24px' }}>BriefWires</Title>
                    <div style={{ width: '30px' }} />
                    <Link to={'/news'}><Text style={{ justifyContent: "center", fontSize: '18px' }}>News</Text></Link>
                </Flex>
                {/* {windowWidth >= 768 && !isAdmin ? <Button type="primary" size="large" onClick={redirectToPayment}>Get Started Free</Button> : null} */}
            </Header>
            <Content style={{ padding: '0 10vw' }}>
                {InnerComponent}
            </Content>
            <Footer
                style={{
                    textAlign: 'center',
                    backgroundColor: 'white',
                }}
            >
                <div style={{ marginBottom: "1vmin" }}>©{new Date().getFullYear()} Ink River, LLC</div>
                <a onClick={showModal}><Text underline>Contact Us</Text></a>
                <div><Link to={"/policy"}><Text underline>Privacy Policy & Terms</Text></Link></div>
            </Footer>
        </Layout >
    );
}

export default MyLayout;
