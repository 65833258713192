import './App.css';
import { LoaderFunctionArgs, RouterProvider, createBrowserRouter, redirect, useRouteError } from 'react-router-dom';
import { getCurrentUser, signOut } from 'aws-amplify/auth';
import { record, configureAutoTrack } from 'aws-amplify/analytics';
import { Amplify } from 'aws-amplify';
import AdminPage from './AdminPage';
import HomePage from './HomePage';
import React, { useEffect, useState } from 'react';
import amplifyconfig from './amplifyconfiguration.json';
import ReactGA from 'react-ga';
import NewsDetailPage from './NewsDetailPage';

const TRACKING_ID = "G-WJSH50CFJH";
ReactGA.initialize(TRACKING_ID);

Amplify.configure(amplifyconfig);

configureAutoTrack({
  // REQUIRED, turn on/off the auto tracking
  enable: true,
  // REQUIRED, the event type, it's one of 'event', 'pageView' or 'session'
  type: 'pageView',
});

function ErrorPage() {
  const error = useRouteError() as any;
  console.error(error);

  return (
    <center id="error-page">
      <h1>Oops!</h1>
      <p>Sorry, an unexpected error has occurred.</p>
      <p>
        <i>{error.statusText || error.message}</i>
      </p>
    </center>
  );
}

const PolicyPageWrapper = () => {
  const [PolicyPage, setPolicyPage] = useState<React.ComponentType<any> | null>(null);

  useEffect(() => {
    const loadComponent = async () => {
      const { default: LoadedPolicyPage } = await import('./PolicyPage');
      setPolicyPage(() => LoadedPolicyPage);
    };

    loadComponent();
  }, []);

  if (!PolicyPage) return <div>Loading...</div>;

  return <PolicyPage />;
};

const NewsPageWrapper = () => {
  const [NewsPage, setNewsPage] = useState<React.ComponentType<any> | null>(null);

  useEffect(() => {
    const loadComponent = async () => {
      const { default: LoadedNewsPage } = await import('./news');
      setNewsPage(() => LoadedNewsPage);
    };

    loadComponent();
  }, []);

  if (!NewsPage) return <div>Loading...</div>;

  return <NewsPage />;
};

const router = createBrowserRouter([
  {
    errorElement: <ErrorPage />,
    children: [
      {
        index: true,
        loader: homeLoader,
        element: <HomePage />,
      },
      {
        path: "admin",
        loader: adminLoader,
        element: <AdminPage />,
      },
      {
        path: "policy",
        element: <PolicyPageWrapper />,
      },
      {
        path: "news",
        element: <NewsPageWrapper />,
      },
      {
        path: "news/:query_term", // This will match paths like /news/123
        element: <NewsDetailPage />, // Component to render for this route
      },
      {
        path: "*",
        element: <HomePage />,
      }
    ],
  },
  {
    path: "/logout",
    async action() {
      // We signout in a "resource route" that we can hit from a fetcher.Form
      await signOut();
      return redirect("/");
    },
  },
]);

async function homeLoader({ }: LoaderFunctionArgs) {
  try {
    const user = await getCurrentUser();
    record({
      name: 'homeLoader',
    });
    if (user) {
      return redirect("/admin");
    } else {
      return null;
    }
  } catch (error) {
    return null;
  }
}

async function adminLoader({ }: LoaderFunctionArgs) {
  try {
    const user = await getCurrentUser();
    record({
      name: 'adminLoader',
    });
    if (user) {
      return { user };
    } else {
      return redirect("/");
    }
  } catch (error) {
    return redirect("/");
  }
}

const trackIncomingLinks = () => {
  // Assuming the URL is https://yourdomain.com/?source=campaignA
  const params = new URLSearchParams(window.location.search);
  const source = params.get('s'); // 'campaignA'
  if (source) {
    record({
      name: 'incomingLinkClick',
      attributes: { source },
    });
  }
};

export default function App() {

  useEffect(() => {
    trackIncomingLinks();
  }, []);

  return (
    <RouterProvider router={router} fallbackElement={<p>Initial Load...</p>} />
  );
}
